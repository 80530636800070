<template>  
<q-layout view="lHh Lpr lFf">
  <q-page-container>
    <q-input outlined placeholder="Nome" v-model="message.name" class="q-mt-sm q-pa-sm"></q-input>    
    <ChatComponent :messages="messages" :userActual="message.name" /> 
    <q-input outlined @keyup.enter="send" placeholder="Digite..." v-model="message.body" class="q-mt-xl q-pa-sm">
      <template v-slot:append>
        <q-icon size="sm" name="send"/>
      </template>
    </q-input>       
  </q-page-container>
</q-layout>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import ChatComponent from './components/ChatComponent.vue';
import Hub from "./Hub";

export default {
  name: 'LayoutDefault',
  components: {
    ChatComponent
  },
  setup () {
    let messages = ref([]);
    let message = reactive({
      name:"",
      body:""
    });
    let _hub = new Hub();

    function send() {
      if(message.body == "") return;
      _hub.connection.invoke("SendMessage", message);
      message.body = "";
    }

    onMounted(() => {
      _hub.connection.start()
      .then(() => {
        console.log("Connected on Hub");
        _hub.connection.on("ReceivedMessage", (msg) => {
          console.log(msg);
          messages.value.push(msg);
        });
      }).catch(err => {
        console.log(err);
      })
    })

    return { 
      send, 
      messages, 
      message 
    }
  }
}
</script>