import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import { ENV } from "./utils/env";

export default class Hub {
    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl(ENV.BASE_URL_SOCKET)
            .configureLogging(LogLevel.Information)
            .build();
    }
}