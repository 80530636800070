let ENV = {};

if (window.location.hostname === "ia-chat.wtfapps.dev.br") {
  let tenant = "prod";
  ENV = {
    TENANT: tenant,
    APP_NAME: "IA-Chat",
    APP_ENV: "prod",
    BASE_URL: "https://ia-chat.wtfapps.dev.br/",
    BASE_URL_SOCKET: "https://ia-chat.wtfapps.dev.br/hub/",
    URL_AWS_BUCKET: "https://ia-chat-storage-prod.s3.amazonaws.com/",
  };
}

if (window.location.hostname === "ia-chat.wtfapps.dev.br") {
  let tenant = "dev";
  ENV = {
    TENANT: tenant,
    APP_NAME: "IA-Chat",
    APP_ENV: "dev",
    BASE_URL: "https://ia-chat.wtfapps.dev.br",
    BASE_URL_SOCKET: "https://ia-chat-api.wtfapps.dev.br/hub",
    URL_AWS_BUCKET: "https://ia-chat-storage-dev.s3.us-east-2.amazonaws.com/",
  };
}

if (window.location.hostname === "localhost") {
  let tenant = "local";
  ENV = {
    TENANT: tenant,
    APP_NAME: "IA-Chat",
    APP_ENV: "local",
    BASE_URL: "https://localhost:8080",
    BASE_URL_SOCKET: "https://localhost:7046/Hub",
    URL_AWS_BUCKET: "https://localhost:8080/",
  };
}

export { ENV };